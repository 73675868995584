import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Text, Div, Anchor } from "atomize"
import "../css/global.css"
const Impressum = () => (
  <Layout>
    <SEO
      title="Corona Navi | Impressum"
      description="Deine zentrale Anlaufstelle für alle Themen rund um Corona."
    />
    <Div tag="section" p={{ t: { xs: "6rem", md: "10rem" } }}>
      <Container>
        <Text
          textWeight="500"
          textSize={{ xs: "title", sm: "heading", md: "display1" }}
          fontFamily="primary"
          m={{ b: "1rem" }}
        >
          Impressum
        </Text>
        <Text tag="p">
          <strong>Convaise UG (haftungsbeschränkt)</strong>
          <br />
          Minervaweg 4 <br />
          85586 Poing <br />
          Deutschland <br />
          <br />
          Amtsgericht München <br />
          HRB: 251483 <br />
          USt-IdNr.: DE326460960 <br />
          Geschäftsführer: Tushaar Bhatt, Jakob von der Haar, Stefan
          Zitzlsperger <br />
          <br />
          Email:{" "}
          <Anchor href="mailto:kontakt@convaise.com">
            kontakt@convaise.com
          </Anchor>{" "}
          <br />
          Webseite:{" "}
          <Anchor
            href="https://www.convaise.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.convaise.com
          </Anchor>
        </Text>
        <Text
          textSize="heading"
          p={{ t: { xs: "1rem", md: "2rem" } }}
          fontFamily="primary"
        >
          Haftungsausschluss
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          Verantwortlichkeit für Inhalte
        </Text>
        <Text tag="p">
          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für
          die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
          jedoch keine Gewähr übernehmen. Darüber hinaus sind wir nach den
          gesetzlichen Bestimmungen für die eigenen Inhalte auf diesen Webseiten
          verantwortlich. Wir weisen in diesem Zusammenhang darauf hin, dass wir
          nicht verpflichtet sind, die übermittelten oder gespeicherten
          Informationen Dritter zu überwachen oder Umstände zu untersuchen, die
          auf eine rechtswidrige Tätigkeit hinweisen. Unsere Verpflichtung zur
          Entfernung oder Sperrung der Nutzung von Informationen nach allgemein
          geltendem Recht bleibt hiervon gemäß §§ 8 bis 10 TMG unberührt.
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          Verantwortlichkeit für Links
        </Text>
        <Text tag="p">
          Die Verantwortung für die Inhalte externer Links (zu Webseiten
          Dritter) liegt ausschließlich bei den Betreibern der verlinkten
          Seiten. Zum Zeitpunkt der Verlinkung waren für uns keine
          Rechtsverstöße ersichtlich. Bei Bekanntwerden von Rechtsverletzungen
          werden wir den entsprechenden Link umgehend entfernen.
        </Text>
        <Text textSize="title" p={{ t: "1rem" }} fontFamily="primary">
          Urheberrecht
        </Text>
        <Text tag="p">
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </Text>
      </Container>
    </Div>
  </Layout>
)

export default Impressum
